import React, { Component } from 'react';
import { Card, Pagination } from 'antd';

export default class CardPagination extends Component {
  onChange = (page, pageSize) => {
    if (this.props.onChange) {
      this.props.onChange(page, pageSize);
    }
  };

  render() {
    const {
      total = 1,
      // size = 14,
      pageSizeOptions = ['20', '40', '60', '80'],
      defaultPageSize = 20,
      current = 1,
      visible = true
    } = this.props;

    return (visible ? 
      <Card style={{ marginBottom: 24 }}>
        <Pagination
          total={total * 1}
          showSizeChanger
          showQuickJumper
          style={{ float: 'right' }}
          onChange={this.onChange}
          onShowSizeChange={this.onChange}
          pageSizeOptions={pageSizeOptions}
          defaultPageSize={defaultPageSize}
          current={current}
        />
      </Card> :null
    );
  }
}
