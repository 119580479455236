
import React, { useEffect, useState } from 'react';

import { getUpdatePrice } from '../../services/productmy';

import { useDispatch } from 'react-redux';

/**
 * 检测是否推送完成
 */
function updatePriceCheck({ onSuccess, onError }) {
  const unmountRef = React.useRef(false);
  const timingRef = React.useRef(null);
  const dispatch = useDispatch()
  const [cachedData, setCachedData] = useState(null);
  const [awaited, setAwaited] = useState(false);
  const [trigger, setTrigger] = useState();
  const [result, setResult] = useState(null);

  useEffect(() => {
    console.log('****** running ********');
    function task() {
      try {
        const tmp = localStorage.getItem('updateprice_task');
        if (!tmp) {
          if (timingRef.current) clearInterval(timingRef.current);
          return;
        }
        setCachedData(tmp);
        setAwaited(true);
        
        getUpdatePrice({
          data: {
            seq_id: tmp
          }
        })
          .then(res => {
            console.log('updateprice_task res', res);
            if (res && res.code === 2000) {
              setResult(res.data);
              // 处理完成
              if (res.data.total_product_num === res.data.finish_product_num) {
                setCachedData(null);
                setAwaited(false);
                localStorage.removeItem('updateprice_task');
                if (timingRef.current) {
                  clearInterval(timingRef.current);
                }
                onSuccess();
              }
            } else {
              setAwaited(false);
              onError(res.data);
              if (timingRef.current) clearInterval(timingRef.current);
            }

            return null;
          })
          .catch(error => {
            console.log('push check fetch error:', error);
            onError(error);
            if (timingRef.current) clearInterval(timingRef.current);
          });
      } catch (error) {
        console.log('updateprice_task error: ', error);
        onError(error);
        if (timingRef.current) clearInterval(timingRef.current);
      }
    }

    task();
    timingRef.current = setInterval(() => {
      task();
    }, 2000);

    return () => {
      unmountRef.current = true;
      if (timingRef.current) clearInterval(timingRef.current);
    };
  }, [trigger]);

  // 手动触发检测
  function run() {
    if (timingRef.current) clearInterval(timingRef.current);
    setTrigger(Date.now());
  }

  return {
    cachedData,
    result,
    awaited,
    run
  };
}

export default updatePriceCheck;
