import React from 'react';
import { routerRedux } from 'dva/router';
import { Modal, Button } from 'antd';

import styles from './index.less';

export default class DSersSpBanner extends React.PureComponent {
  handleCancel = () => {
    localStorage.setItem('dsersSpBannerOnce', 1);
    this.forceUpdate();
  };

  goPool = () => {
    localStorage.setItem('dsersSpBannerOnce', 1);
    this.props.dispatch(routerRedux.push('/storemanage/market'));
  };

  render() {
    const dsersSpBanner = localStorage.getItem('dsersSpBannerOnce');

    return (
      <Modal
        visible={false} // { !dsersSpBanner && flag }
        footer={false}
        width={680}
        maskClosable={false}
        closable={false}
      >
        <div className={styles.wrap}>
          <h3>Good News from AliExpress</h3>
          <div className={styles.tips}>
            <p>Dear DSers users</p>
            <p>
              AliExpress officially provides products with a secure supply
              support.<br />
              All items are handled by AliExpress directly and{' '}
              <span>capable of shipping out within 24~48 hours.</span>
              <br />
              The customer service is available at
              dropshipping@list.alibaba-inc.com<br />
              Thank you for all the customers asking, the situation in China is
              getting better every day.<br />
              We hope your business will go well and wish you good luck for
              everything.
            </p>
          </div>
          <div className={styles.btns}>
            <Button size="large" onClick={this.handleCancel}>
              Got it
            </Button>
            <Button
              type="primary"
              size="large"
              href="/storemanage/market"
              target="_blank"
              onClick={this.handleCancel}
            >
              Check more
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
