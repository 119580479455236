import React, { useEffect, useState } from 'react';
import { connect } from 'dva';

import { Drawer, Button, Collapse } from 'antd';
import intl from 'react-intl-universal';
import importlistguide from 'assets/importlistguide.gif';
import myproductguide from 'assets/myproductguide.gif';

import { GuildeAction } from 'actions';

import styles from './MoreActionGuide.less';

const { Panel } = Collapse;
function MoreActionGuide(props) {
  const [visible,setVisible] = useState(false)
  useEffect(()=>{
    if((Math.round(new Date() / 1000) > 1662513851 || 
    localStorage.getItem('closeMoreActionGuide')==='true')
    ){
      setVisible(false)
    }else{
      setVisible(true)
    }
  }, []);
  const closehandler = e => {
    localStorage.setItem('closeMoreActionGuide', true);
    setVisible(false);
  };
  return true  && visible ? (
    <div className={styles.guideContainer}>
      <span className={styles.sanjiao}></span>
      <img
        width={438}
        height={184}
        src={props.type === 'importlist' ? importlistguide : myproductguide}
        alt=""
      />
      <div className={styles.textContainer}>
        {props.type === 'importlist' ? (
          <p>{intl.getHTML('public.importlist_card_guide_text')}</p>
        ) : (
          <p style={{ margin: 0 }}>
            {intl.getHTML('public.product_card_guide_text')}
          </p>
        )}
      </div>
      <div className={styles.disappearContainer}>
        <span onClick={closehandler}>
          {intl.getHTML('public.input_guide_btn')}
        </span>
      </div>
    </div>
  ) : null;
}
export default connect(({ global }) => ({
  actionGuide: global.actionGuide
}))(React.memo(MoreActionGuide));
