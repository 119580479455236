import React, { Fragment } from 'react';
import { connect } from 'dva';
import {
  Drawer,
  Radio,
  Checkbox,
  Row,
  Col,
  Button,
  Icon,
  Modal,
  message,
  Alert
} from 'antd';

const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

import intl from 'react-intl-universal';

import styles from './SplitProduct.less';
import './SplitProduct.css';

@connect(({ productmy, loading }) => ({
  productmy,
  loading: loading.models.productmy,
  inviteLoading: loading.effects['productmy/mappingInvite']
}))
export default class SplitProduct extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      splitType: 'variant', // 按照什么拆分 option(默认) || variant
      optionValue: '',
      variantType: '',
      variantValue: [],
      confirmSplitVisible:false,
      confirmLoading:false
    };
  }

  componentWillReceiveProps(props) {
    if (!props.prod) {
      return;
    }

    const { prod: { prod: { detail = {} } } } = props;

    const { options = [], variants = [] } = detail;

    variants.forEach(variant => {
      variant.options = [];

      options.forEach((v, i) => {
        variant.options.push(variant[`option${i + 1}`]);
      });
    });

    this.variants = variants;
    this.setState({
      variantValue: [],
      splitType: 'variant',
      optionValue: '',
      variantType: ''
    });
  }

  variants = null;

  // 改变拆分分类
  changeSplitType = e => {
    const { value } = e.target;

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: value === 'option' ? 'splitByOption' : 'splitByVariant'
    });

    this.setState({
      splitType: e.target.value
    });
  };

  // 改变option value
  changeOptionValue = e => {
    this.setState({
      optionValue: e.target.value
    });
  };

  // 改变variant
  changeVariantValue = (v, optionName) => {
    this.setState({
      variantValue: [...v],
      variantType: v.length ? optionName : ''
    });
  };

  // 确认拆分
  confirmSplit = () => {
    if (!this.getSplitValue()) {
      message.error(intl.get('product_split.select_split_mothod'));
      return;
    }
    this.setState({
      confirmSplitVisible:true
    })
    // Modal.confirm({
    //   title: intl.get('product_split.confirm_split'),
    //   okText: intl.getHTML('public.ok'),
    //   cancelText: intl.get('public.cancel'),
    //   closeIcon:'',
    //   className:'deleteProsplit',
    //   onOk: () => {
        
    //   }
    // });
  };

  // 获取提交数据
  getPostData = id => {
    const { splitType } = this.state;
    const obj = {
      [id]: this.props.isImportListPage
        ? this.props.prod.unique_id
        : this.props.prod.id,
      type: splitType,
      supplier_type : this.props.prod?.supplier_type
    };

    const v = this.getSplitValue();

    if (splitType == 'option') {
      obj.option = v;
    } else {
      obj.variant = v;
    }

    return obj;
  };

  // 获取拆分的值
  getSplitValue = () => {
    const { splitType, optionValue, variantValue, variantType } = this.state;

    const { prod: { prod: { detail: { options } } } } = this.props;

    if (splitType == 'option') {
      return optionValue;
    } else {
      const obj = {};
      if (variantValue.length) {
        obj[variantType] = variantValue;
      }
      if (Object.values(obj).length) {
        return obj;
      }
      return '';
    }
  };

  confirmSplitHandler = ()=>{
    this.setState({
      confirmLoading:true
    })
    if (this.props.isImportListPage) {
      return new Promise((resolve, reject) => {
        this.props.dispatch({
          type: 'productmy/productSplit',
          payload: {
            data: this.getPostData('unique_id'),
            callback: d => {
              resolve();
              if (d.code == 2000 || d.code == 2010) {
                // 解决 Modal 和 Drawer 组件同时存在时造成 body overflow: hidden 无滚动条
                setTimeout(() => {
                  this.props.hideSplitProduct(true);
                }, 500);
                this.props.dispatch({
                  type: 'topBtn/notificationUnreadLength',
                });
              }
              this.setState({
                confirmSplitVisible:false,
                confirmLoading:false
              })
            }
          }
        });
      }).catch(() => console.log('Oops errors!'));
    } else {
      return new Promise((resolve, reject) => {
        this.props.dispatch({
          type: 'productmy/splitProduct',
          payload: {
            data: this.getPostData('id'),
            callback: d => {
              resolve();
              if (d.code == 2000 || d.code == 2010) {
                setTimeout(() => {
                  this.props.hideSplitProduct(true);
                }, 500);
                this.props.dispatch({
                  type: 'topBtn/notificationUnreadLength',
                });
              }
              this.setState({
                confirmSplitVisible:false,
                confirmLoading:false
              })
            }
          }
        });
      }).catch(() => console.log('Oops errors!'));
    }
  }

  render() {
    const { hideSplitProduct, visible, prod } = this.props;

    if (!prod) {
      return null;
    }

    const { splitType, optionValue, variantValue, variantType } = this.state;

    const { prod: { detail = {} } } = prod;

    const { options = [] } = detail;

    console.log( variantValue, 778  );

    return (
      <Drawer
        visible={visible}
        maskClosable
        width={868}
        onClose={hideSplitProduct}
        destroyOnClose
        className="wapw_Drat"
        className={styles.splitWrap}
      >
        <h3 className={styles.splitTitle}>{intl.get('product_split.title')}</h3>
        <RadioGroup
          name="type"
          value={splitType}
          className={styles.width100Percent}
          onChange={this.changeSplitType}
        >
          <dl>
            <dd>
              <Radio value="variant">
                {intl.get('product_split.split_by_variant')}
              </Radio>
            </dd>
            <dt>{intl.get('product_split.split_by_variant_dec')}</dt>
          </dl>

          <div className={styles.variants}>
            {splitType == 'variant' &&
            variantValue &&
            variantValue.flat().length ? (
              <p className="alert_split">
                <Alert
                  message={intl.get('product_split.tip')}
                  type="warning"
                  showIcon={true}
                  icon={<Icon type="info-circle" theme="filled" />}
                />
              </p>
            ) : null}
            {options.map((option, i) => {
              let disabled = true;
              const { values = [] } = option;
              if (splitType == 'option') {
                disabled = true;
              } else if (variantType != option.name && variantType) {
                disabled = true;
              } else {
                disabled = false;
              }

              return (
                <div className="checkbox-u" key={option.name} gutter={[16, 16]}>
                  <p className="checkbox-u-fr" span={4}>
                    <p className={styles.optionName}>{option.name}</p>
                  </p>
                  <p span={19} className="checkbox-u-br">
                    <CheckboxGroup
                      disabled={disabled}
                      value={variantValue}
                      onChange={e => this.changeVariantValue(e, option.name)}
                    >
                      {values.map(v => {
                        return (
                          <Checkbox
                            value={v}
                            key={v}
                            disabled={
                              variantValue.length == values.length - 1 &&
                              !variantValue.includes(v)
                            }
                          >
                            {v}
                          </Checkbox>
                        );
                      })}
                    </CheckboxGroup>
                  </p>
                </div>
              );
            })}
          </div>

          <dl>
            <dd>
              <Radio value="option">
                {intl.get('product_split.split_by_option')}
              </Radio>
            </dd>
            <dt>{intl.get('product_split.split_by_option_dec')}</dt>
          </dl>

          <RadioGroup
            name="option"
            value={optionValue}
            disabled={splitType == 'variant'}
            className={styles.width100Percent2}
            onChange={this.changeOptionValue}
          >
            <ul className={styles.options}>
              {options.map((option, i) => {
                const { values = [] } = option;
                return (
                  <Fragment key={option.name}>
                    <li>
                      <Row type="flex">
                        <Col span={24}>
                          <Radio
                            value={option.name}
                            style={{ whiteSpace: 'normal' }}
                          >
                            <span className="radiop">{option.name}</span>
                            <p className={styles.optionDec}>
                              {intl.get('product_split.option_tip', {
                                length: values.length,
                                optionName: option.name
                              })}
                            </p>
                            <p className="optionDecK">
                              {option.values.map((item, i) => {
                                return (
                                  <div
                                    className={
                                      splitType == 'variant'
                                        ? 'optionDecI fffw'
                                        : 'optionDecI'
                                    }
                                    key={i}
                                  >
                                    {item}
                                  </div>
                                );
                              })}
                            </p>
                          </Radio>
                        </Col>
                      </Row>
                    </li>
                    {options.length - 1 != i ? <li></li> : null}
                  </Fragment>
                );
              })}
            </ul>
          </RadioGroup>
        </RadioGroup>

        <div className="bottom_btn">
          <div className={styles.splitBtn}>
            <Button onClick={hideSplitProduct} data="bk">
              {intl.get('public.cancel')}
            </Button>
            <Button type="primary" onClick={this.confirmSplit} data="dx">
              {intl.get('product_split.split')}
            </Button>
          </div>
        </div>
        <Modal
          title={intl.getHTML('product_split.confirm_split')}
          visible={this.state.confirmSplitVisible}
          onCancel={() => {
            this.setState({
              confirmSplitVisible: false
            });
          }}
          className={styles.splitModal}
          footer={
            <div>
              <Button
                loading={this.state.confirmLoading}
                onClick={this.confirmSplitHandler}
                className={styles.btn}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </div>
          }
        >
          <div>{intl.getHTML('product_split.confirm_split_desc')}</div>
        </Modal>
      </Drawer>
    );
  }
}
