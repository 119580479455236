import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Input } from 'antd';

import styles from './index.less';

@connect(({ productmy, loading, login }) => ({
  productmy,
  userInfo: login.userInfo,
  loading: loading.models.productmy,
  okLoading: loading.effects['productmy/aeDataAuthorization']
}))
export default class AEDataAuthorization extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      okDisable: true,
      showModal: false
    };
  }

  componentDidMount() {
    this.getHasSet();
  }

  getHasSet = () => {
    const { userInfo } = this.props;
    if (userInfo.ae_back == 0) {
      this.setState({
        showModal: true
      });
    }
  };

  changeInput = e => {
    const v = e.target.value.trim().toLowerCase() == 'confirm';
    this.setState({
      okDisable: !v
    });
  };

  handleClick = flag => {
    this.props.dispatch({
      type: 'productmy/aeDataAuthorization',
      payload: {
        data: {
          ae_back: flag
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              showModal: false
            });
            window.location.reload();
          }
        }
      }
    });
  };

  render() {
    const { okLoading } = this.props;

    const { okDisable, showModal } = this.state;

    return (
      <Modal
        visible={false} // { showModal }
        footer={false}
        width={748}
        maskClosable={false}
        closable={false}
      >
        <div className={styles.wrap}>
          <h3>AliExpress Data Authorization</h3>
          <dl>
            <dd>
              AliExpress wants to access your data from DSers to develop its
              Dropshipping Center. DSers is following the privacy policy and
              GDPR strictly. To ensure this, we need your authorization to pass
              this data to AliExpress. AliExpress is asking for the following
              datas:
            </dd>
            <dt>
              <ol>
                <li>The domains of your store.</li>
                <li>The price of the products you published on your site.</li>
                <li>The cost of the products on AliExpress.</li>
                <li>The amount of orders on Shopify.</li>
                <li>The time of the transactions.</li>
                <li>The total number of products sold in a given period.</li>
                <li>
                  The number of customers who have bought AliExpress sourced
                  products.
                </li>
                <li>All the information about your purchases on AliExpress.</li>
              </ol>
            </dt>
          </dl>
          <h4>Input CONFIRM below to accept or click SKIP to refuse.</h4>
          <div className={styles.toolLine}>
            <Input size="large" onChange={this.changeInput} />
            <Button
              type="primary"
              size="large"
              disabled={okDisable}
              onClick={() => this.handleClick(2)}
              loading={okLoading}
            >
              Ok
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => this.handleClick(1)}
            >
              Skip
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
