import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Tooltip, Icon, Radio } from 'antd';
import style from './Updateprice.less';
import '../MyProduct.css';
import './modalCountryIcons.css';
import countryStyles from 'common/country_icon.js';
import { Post } from 'utils/request';
import useUpdatePriceCheck from '../useUpdatePriceCheck';
import intl from 'react-intl-universal';

function UpdatePrice({ visible, handleClose, selectProduct, onStart, type, storeid, listData }) {
    const [switchProdutData, setSwitchProdutData] = useState()
    const [countryList, setCountryList] = useState([])
    const [selectCountry, setSelectCountry] = useState()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [inputValue, setInputValue] = useState('')

    let ref = useRef(null)

    useEffect(() => {

        if (type === 'importList') {
            let confirmProduct = []
            listData?.forEach(item => {
                if (selectProduct.includes(item.unique_id)) {
                    confirmProduct.push(item.id)
                }
            })
            setSwitchProdutData(confirmProduct)
        } else {
            setSwitchProdutData(selectProduct)
        }

        resetCountryList()
    }, [])

    const resetCountryList = () => {
        let arr = []
        for (let key in countryStyles) {
            if (key !== 'US') {
                arr.push(countryStyles[key])
            }
        }
        setCountryList(arr)
    }

    const handleCountry = (key) => {
        setSelectCountry(key.target.value)
    }

    const handleFilter = () => {
        if (ref.value) {
            let arr = []
            for (let key in countryStyles) {
                if (key !== 'US' && countryStyles[key][1].toLowerCase().includes(ref.value.toLowerCase())) {
                    arr.push(countryStyles[key])
                }
            }
            setCountryList(arr)
        } else {
            resetCountryList()
        }
    }

    const handleClear = () => {
        resetCountryList()
        setInputValue('')
        ref.value = ''
    }

    const handleConfirm = async () => {
        if (selectCountry) {
            setLoading(true)
            if (type === 'importList') {
                const res = await Post('/prod/import_list/price/refresh', {
                    data: {
                        ship_to: selectCountry.toUpperCase(),
                        ids: switchProdutData
                    }
                })
                if (res.code === 2000 && res?.data.seq_id) {
                    onStart(res?.data.seq_id)
                } else if (res.code === 2010 && res.msg) {
                    setErrorMessage(res.msg)
                }
                setLoading(false)
            } else {
                const res = await Post('/prod/my_product/price/refresh', {
                    data: {
                        ship_to: selectCountry.toUpperCase(),
                        ids: switchProdutData,
                        store_id: storeid
                    }
                })
                if (res.code === 2000 && res?.data.seq_id) {
                    onStart(res?.data.seq_id)
                } else if (res.code === 2010 && res.msg) {
                    setErrorMessage(res.msg)
                }
                setLoading(false)
            }
            setLoading(false)
        }
    }

    return (
        <Modal
            style={{ width: '480px', height: '564px' }}
            visible={visible}
            title={intl.get('product.chooseCountry')}
            footer={
                <div className={style.footerButton}>
                    <Button style={selectCountry ? { background: '#FF8F00', color: "#ffffff" } : null} loading={loading} onClick={handleConfirm}>{intl.get('public.start')}</Button>
                </div>
            }
            onCancel={handleClose}
            maskClosable={false}
            destroyOnClose={true}
        >
            <div>
                <div>{intl.get('product.chooseSub')}</div>
                <div style={{ marginLeft: '0', marginTop: "8px", marginBottom: "12px" }} className="inputBoxY">
                    <input
                        maxLength={50}
                        ref={node => {
                            ref = node;
                        }}
                        onChange={(e) => { setInputValue(e.target.value) }}
                        placeholder={intl.get('product.selectCountry')}
                    />
                    <div className="add" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            inputValue ?
                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <i class="material-icons notranslate"
                                    onClick={handleClear}
                                    style={{ fontSize: '16px', cursor: 'pointer', marginRight: '7px' }} >cancel</i></span> : null
                        }
                        <Button
                            type="primary"
                            style={{ fontSize: 22, display: "flex", alignItems: "center", justifyContent: "center" }}
                            onClick={handleFilter}
                        >
                            <i style={{ color: 'white' }} class="material-icons notranslate">search</i>
                        </Button>
                    </div>
                </div>
                {errorMessage ?
                    errorMessage === "one is running please wait" ?
                        <div style={{ color: '#FF0000' }}>{intl.get('product.error_one')}</div>
                        :
                        <div style={{ color: '#FF0000' }}>{intl.get('product.error_two_title')}
                            <Tooltip
                                title={
                                    intl.get('product.error_two_sub')
                                }
                            >
                                <Icon
                                    type="question-circle"
                                    theme="outlined"
                                    style={{
                                        marginLeft: 2,
                                    }}
                                />
                            </Tooltip></div>
                    : null
                }
                <div className={style.countryBox}>
                    <Radio.Group value={selectCountry} onChange={handleCountry} size='small'>
                        {
                            countryList.map((key, index) => {
                                return <div className={style.outBox} key={key}>
                                    <Radio value={key[0]}></Radio><div className={`css_flag_2 font_2 css_${key[0]}`}><i>{key[1]}</i></div>
                                </div>
                            })
                        }
                    </Radio.Group>
                </div>
            </div>
        </Modal>
    )
}

export default UpdatePrice