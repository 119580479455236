export default {
  "AF": [
      "af",
      "Afghanistan"
  ],
  "ALA": [
      "ala",
      "Aland Islands"
  ],
  "AL": [
      "al",
      "Albania"
  ],
  "GBA": [
      "gba",
      "Alderney"
  ],
  "DZ": [
      "dz",
      "Algeria"
  ],
  "AS": [
      "as",
      "American Samoa"
  ],
  "AD": [
      "ad",
      "Andorra"
  ],
  "AO": [
      "ao",
      "Angola"
  ],
  "AI": [
      "ai",
      "Anguilla"
  ],
  "AG": [
      "ag",
      "Antigua and Barbuda"
  ],
  "AR": [
      "ar",
      "Argentina"
  ],
  "AM": [
      "am",
      "Armenia"
  ],
  "AW": [
      "aw",
      "Aruba"
  ],
  "ASC": [
      "asc",
      "Ascension Island"
  ],
  "AU": [
      "au",
      "Australia"
  ],
  "AT": [
      "at",
      "Austria"
  ],
  "AZ": [
      "az",
      "Azerbaijan"
  ],
  "BS": [
      "bs",
      "Bahamas"
  ],
  "BH": [
      "bh",
      "Bahrain"
  ],
  "BD": [
      "bd",
      "Bangladesh"
  ],
  "BB": [
      "bb",
      "Barbados"
  ],
  "BY": [
      "by",
      "Belarus"
  ],
  "BE": [
      "be",
      "Belgium"
  ],
  "BZ": [
      "bz",
      "Belize"
  ],
  "BJ": [
      "bj",
      "Benin"
  ],
  "BM": [
      "bm",
      "Bermuda"
  ],
  "BT": [
      "bt",
      "Bhutan"
  ],
  "BO": [
      "bo",
      "Bolivia"
  ],
  "BA": [
      "ba",
      "Bosnia and Herzegovina"
  ],
  "BW": [
      "bw",
      "Botswana"
  ],
  "BR": [
      "br",
      "Brazil"
  ],
  "BN": [
      "bn",
      "Brunei"
  ],
  "BG": [
      "bg",
      "Bulgaria"
  ],
  "BF": [
      "bf",
      "Burkina Faso"
  ],
  "BI": [
      "bi",
      "Burundi"
  ],
  "KH": [
      "kh",
      "Cambodia"
  ],
  "CM": [
      "cm",
      "Cameroon"
  ],
  "CA": [
      "ca",
      "Canada"
  ],
  "CV": [
      "cv",
      "Cape Verde"
  ],
  "BQ": [
      "bq",
      "Caribbean Netherlands"
  ],
  "KY": [
      "ky",
      "Cayman Islands"
  ],
  "CF": [
      "cf",
      "Central African Republic"
  ],
  "TD": [
      "td",
      "Chad"
  ],
  "CL": [
      "cl",
      "Chile"
  ],
  "CX": [
      "cx",
      "Christmas Island"
  ],
  "CC": [
      "cc",
      "Cocos (Keeling) Islands"
  ],
  "CO": [
      "co",
      "Colombia"
  ],
  "KM": [
      "km",
      "Comoros"
  ],
  "ZR": [
      "zr",
      "Congo, The Democratic Republic Of The"
  ],
  "CG": [
      "cg",
      "Congo, The Republic of Congo"
  ],
  "CK": [
      "ck",
      "Cook Islands"
  ],
  "CR": [
      "cr",
      "Costa Rica"
  ],
  "CI": [
      "ci",
      "Cote D'Ivoire"
  ],
  "HR": [
      "hr",
      "Croatia (local name: Hrvatska)"
  ],
  "CW": [
      "cw",
      "Curacao"
  ],
  "CY": [
      "cy",
      "Cyprus"
  ],
  "CZ": [
      "cz",
      "Czech Republic"
  ],
  "DK": [
      "dk",
      "Denmark"
  ],
  "DJ": [
      "dj",
      "Djibouti"
  ],
  "DM": [
      "dm",
      "Dominica"
  ],
  "DO": [
      "do",
      "Dominican Republic"
  ],
  "EC": [
      "ec",
      "Ecuador"
  ],
  "EG": [
      "eg",
      "Egypt"
  ],
  "SV": [
      "sv",
      "El Salvador"
  ],
  "GQ": [
      "gq",
      "Equatorial Guinea"
  ],
  "ER": [
      "er",
      "Eritrea"
  ],
  "EE": [
      "ee",
      "Estonia"
  ],
  "ET": [
      "et",
      "Ethiopia"
  ],
  "FK": [
      "fk",
      "Falkland Islands (Malvinas)"
  ],
  "FO": [
      "fo",
      "Faroe Islands"
  ],
  "FJ": [
      "fj",
      "Fiji"
  ],
  "FI": [
      "fi",
      "Finland"
  ],
  "FR": [
      "fr",
      "France"
  ],
  "GF": [
      "gf",
      "French Guiana"
  ],
  "PF": [
      "pf",
      "French Polynesia"
  ],
  "GA": [
      "ga",
      "Gabon"
  ],
  "GM": [
      "gm",
      "Gambia"
  ],
  "GE": [
      "ge",
      "Georgia"
  ],
  "DE": [
      "de",
      "Germany"
  ],
  "GH": [
      "gh",
      "Ghana"
  ],
  "GI": [
      "gi",
      "Gibraltar"
  ],
  "GR": [
      "gr",
      "Greece"
  ],
  "GL": [
      "gl",
      "Greenland"
  ],
  "GD": [
      "gd",
      "Grenada"
  ],
  "GP": [
      "gp",
      "Guadeloupe"
  ],
  "GU": [
      "gu",
      "Guam"
  ],
  "GT": [
      "gt",
      "Guatemala"
  ],
  "GGY": [
      "ggy",
      "Guernsey"
  ],
  "GN": [
      "gn",
      "Guinea"
  ],
  "GW": [
      "gw",
      "Guinea-Bissau"
  ],
  "GY": [
      "gy",
      "Guyana"
  ],
  "HT": [
      "ht",
      "Haiti"
  ],
  "HN": [
      "hn",
      "Honduras"
  ],
  "HK": [
      "hk",
      "Hong Kong,China"
  ],
  "HU": [
      "hu",
      "Hungary"
  ],
  "IS": [
      "is",
      "Iceland"
  ],
  "IN": [
      "in",
      "India"
  ],
  "ID": [
      "id",
      "Indonesia"
  ],
  "IQ": [
      "iq",
      "Iraq"
  ],
  "IE": [
      "ie",
      "Ireland"
  ],
  "IL": [
      "il",
      "Israel"
  ],
  "IT": [
      "it",
      "Italy"
  ],
  "JM": [
      "jm",
      "Jamaica"
  ],
  "JP": [
      "jp",
      "Japan"
  ],
  "JEY": [
      "jey",
      "Jersey"
  ],
  "JO": [
      "jo",
      "Jordan"
  ],
  "KZ": [
      "kz",
      "Kazakhstan"
  ],
  "KE": [
      "ke",
      "Kenya"
  ],
  "KI": [
      "ki",
      "Kiribati"
  ],
  "KR": [
      "kr",
      "Korea"
  ],
  "KS": [
      "ks",
      "Kosovo"
  ],
  "KW": [
      "kw",
      "Kuwait"
  ],
  "KG": [
      "kg",
      "Kyrgyzstan"
  ],
  "LA": [
      "la",
      "Lao People's Democratic Republic"
  ],
  "LV": [
      "lv",
      "Latvia"
  ],
  "LB": [
      "lb",
      "Lebanon"
  ],
  "LS": [
      "ls",
      "Lesotho"
  ],
  "LR": [
      "lr",
      "Liberia"
  ],
  "LY": [
      "ly",
      "Libya"
  ],
  "LI": [
      "li",
      "Liechtenstein"
  ],
  "LT": [
      "lt",
      "Lithuania"
  ],
  "LU": [
      "lu",
      "Luxembourg"
  ],
  "MO": [
      "mo",
      "Macau,China"
  ],
  "MK": [
      "mk",
      "Macedonia"
  ],
  "MG": [
      "mg",
      "Madagascar"
  ],
  "MW": [
      "mw",
      "Malawi"
  ],
  "MY": [
      "my",
      "Malaysia"
  ],
  "MV": [
      "mv",
      "Maldives"
  ],
  "ML": [
      "ml",
      "Mali"
  ],
  "MT": [
      "mt",
      "Malta"
  ],
  "MH": [
      "mh",
      "Marshall Islands"
  ],
  "MQ": [
      "mq",
      "Martinique"
  ],
  "MR": [
      "mr",
      "Mauritania"
  ],
  "MU": [
      "mu",
      "Mauritius"
  ],
  "YT": [
      "yt",
      "Mayotte"
  ],
  "MX": [
      "mx",
      "Mexico"
  ],
  "FM": [
      "fm",
      "Micronesia"
  ],
  "MD": [
      "md",
      "Moldova"
  ],
  "MC": [
      "mc",
      "Monaco"
  ],
  "MN": [
      "mn",
      "Mongolia"
  ],
  "MNE": [
      "mne",
      "Montenegro"
  ],
  "MS": [
      "ms",
      "Montserrat"
  ],
  "MA": [
      "ma",
      "Morocco"
  ],
  "MZ": [
      "mz",
      "Mozambique"
  ],
  "MM": [
      "mm",
      "Myanmar"
  ],
  "NA": [
      "na",
      "Namibia"
  ],
  "NR": [
      "nr",
      "Nauru"
  ],
  "NP": [
      "np",
      "Nepal"
  ],
  "NL": [
      "nl",
      "Netherlands"
  ],
  "AN": [
      "an",
      "Netherlands Antilles"
  ],
  "NC": [
      "nc",
      "New Caledonia"
  ],
  "NZ": [
      "nz",
      "New Zealand"
  ],
  "NI": [
      "ni",
      "Nicaragua"
  ],
  "NE": [
      "ne",
      "Niger"
  ],
  "NG": [
      "ng",
      "Nigeria"
  ],
  "NU": [
      "nu",
      "Niue"
  ],
  "NF": [
      "nf",
      "Norfolk Island"
  ],
  "MP": [
      "mp",
      "Northern Mariana Islands"
  ],
  "NO": [
      "no",
      "Norway"
  ],
  "OM": [
      "om",
      "Oman"
  ],
  "OTHER": [
      "other",
      "Other Country"
  ],
  "PK": [
      "pk",
      "Pakistan"
  ],
  "PW": [
      "pw",
      "Palau"
  ],
  "PS": [
      "ps",
      "Palestine"
  ],
  "PA": [
      "pa",
      "Panama"
  ],
  "PG": [
      "pg",
      "Papua New Guinea"
  ],
  "PY": [
      "py",
      "Paraguay"
  ],
  "PE": [
      "pe",
      "Peru"
  ],
  "PH": [
      "ph",
      "Philippines"
  ],
  "PL": [
      "pl",
      "Poland"
  ],
  "PT": [
      "pt",
      "Portugal"
  ],
  "PR": [
      "pr",
      "Puerto Rico"
  ],
  "QA": [
      "qa",
      "Qatar"
  ],
  "RE": [
      "re",
      "Reunion"
  ],
  "RO": [
      "ro",
      "Romania"
  ],
  "RU": [
      "ru",
      "Russian Federation"
  ],
  "RW": [
      "rw",
      "Rwanda"
  ],
  "BLM": [
      "blm",
      "Saint Barthelemy"
  ],
  "KN": [
      "kn",
      "Saint Kitts and Nevis"
  ],
  "LC": [
      "lc",
      "Saint Lucia"
  ],
  "MAF": [
      "maf",
      "Saint Martin"
  ],
  "VC": [
      "vc",
      "Saint Vincent and the Grenadines"
  ],
  "WS": [
      "ws",
      "Samoa"
  ],
  "SM": [
      "sm",
      "San Marino"
  ],
  "ST": [
      "st",
      "Sao Tome and Principe"
  ],
  "SA": [
      "sa",
      "Saudi Arabia"
  ],
  "SN": [
      "sn",
      "Senegal"
  ],
  "SRB": [
      "srb",
      "Serbia"
  ],
  "SC": [
      "sc",
      "Seychelles"
  ],
  "SL": [
      "sl",
      "Sierra Leone"
  ],
  "SG": [
      "sg",
      "Singapore"
  ],
  "SX": [
      "sx",
      "Sint Maarten"
  ],
  "SK": [
      "sk",
      "Slovakia (Slovak Republic)"
  ],
  "SI": [
      "si",
      "Slovenia"
  ],
  "SB": [
      "sb",
      "Solomon Islands"
  ],
  "SO": [
      "so",
      "Somalia"
  ],
  "ZA": [
      "za",
      "South Africa"
  ],
  "SGS": [
      "sgs",
      "South Georgia and the South Sandwich Islands"
  ],
  "SS": [
      "ss",
      "South Sudan"
  ],
  "ES": [
      "es",
      "Spain"
  ],
  "LK": [
      "lk",
      "Sri Lanka"
  ],
  "PM": [
      "pm",
      "St. Pierre and Miquelon"
  ],
  "SR": [
      "sr",
      "Suriname"
  ],
  "SZ": [
      "sz",
      "Swaziland"
  ],
  "SE": [
      "se",
      "Sweden"
  ],
  "CH": [
      "ch",
      "Switzerland"
  ],
  "TW": [
      "tw",
      "Taiwan,China"
  ],
  "TJ": [
      "tj",
      "Tajikistan"
  ],
  "TZ": [
      "tz",
      "Tanzania"
  ],
  "TH": [
      "th",
      "Thailand"
  ],
  "TLS": [
      "tls",
      "Timor-Leste"
  ],
  "TG": [
      "tg",
      "Togo"
  ],
  "TO": [
      "to",
      "Tonga"
  ],
  "TT": [
      "tt",
      "Trinidad and Tobago"
  ],
  "TN": [
      "tn",
      "Tunisia"
  ],
  "TR": [
      "tr",
      "Turkey"
  ],
  "TM": [
      "tm",
      "Turkmenistan"
  ],
  "TC": [
      "tc",
      "Turks and Caicos Islands"
  ],
  "TV": [
      "tv",
      "Tuvalu"
  ],
  "UG": [
      "ug",
      "Uganda"
  ],
  "UA": [
      "ua",
      "Ukraine"
  ],
  "AE": [
      "ae",
      "United Arab Emirates"
  ],
  "UK": [
      "uk",
      "United Kingdom"
  ],
  "US": [
      "us",
      "United States"
  ],
  "UY": [
      "uy",
      "Uruguay"
  ],
  "UZ": [
      "uz",
      "Uzbekistan"
  ],
  "VU": [
      "vu",
      "Vanuatu"
  ],
  "VA": [
      "va",
      "Vatican City State (Holy See)"
  ],
  "VE": [
      "ve",
      "Venezuela"
  ],
  "VN": [
      "vn",
      "Vietnam"
  ],
  "VG": [
      "vg",
      "Virgin Islands (British)"
  ],
  "VI": [
      "vi",
      "Virgin Islands (U.S.)"
  ],
  "WF": [
      "wf",
      "Wallis And Futuna Islands"
  ],
  "YE": [
      "ye",
      "Yemen"
  ],
  "ZM": [
      "zm",
      "Zambia"
  ],
  "EAZ": [
      "eaz",
      "Zanzibar"
  ],
  "ZW": [
      "zw",
      "Zimbabwe"
  ]
}