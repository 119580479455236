import React from 'react';
import { Modal, Button } from 'antd';

import intl from 'react-intl-universal';

import styles from './index.less';
import img from '../../assets/syncordermodal.jpg';

export default class ErrorCashbackCode extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  componentDidMount() {
    // const {
    //   status
    // } = this.userCurrent;
    // let v = status+'';
    // if( v.charAt( v.length - 1 ) == 1 && !localStorage.getItem( 'errorCashbackCodeTip' ) ){
    //   this.setState({
    //     visible: true
    //   });
    // }
  }

  // 隐藏弹窗
  handleModalAD = () => {
    this.setState({
      visible: false
    });
    localStorage.setItem('errorCashbackCodeTip', 1);
    window.location.href = '/app/settings?id=6';
  };

  render() {
    const { visible } = this.state;

    return (
      <Modal
        visible={visible}
        footer={false}
        width={748}
        maskClosable={false}
        closable={false}
        onCancel={this.handleCancel}
      >
        <div className={styles.wrap}>
          <h3>{intl.getHTML('error_cashback_code.title')}</h3>
          <h4>{intl.get('error_cashback_code.title_dec')}</h4>
          <div className={styles.tips}>
            <p>{intl.get('error_cashback_code.p1')}</p>
            <p>{intl.get('error_cashback_code.p2')}</p>
            <p>{intl.get('error_cashback_code.p3')}</p>
          </div>
          <Button type="primary" onClick={this.handleModalAD}>
            {intl.get('public.check_it')}
          </Button>
        </div>
        <img src={img} className={styles.bg} />
      </Modal>
    );
  }
}
